import { ChevronLeft } from "./ChevronLeft";
import { Edit } from "./Edit";
import { Info } from "./Info";
import { Menu } from "./Menu";
import { Tune } from "./Tune";

export const Icons = {
	ChevronLeft,
	Edit,
	Info,
	Menu,
	Tune,
};
