import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

import * as Sentry from "@sentry/react";

import { BACKEND_URL, BACKEND_WS_URL, ENVIRONMENT, FRONTEND_URL, NAME, VERSION } from "./config";
import { omitDeep } from "./utils/apollo";
import { automaticallyPersistedQueryLink } from "./client.automaticallyPersistedQueryLink";
import { getTokenPayload } from "./auth";
import unionBy from "lodash.unionby";

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { __DEV__ } from "@apollo/client/utilities/globals";

const getToken = () => {
	const accessToken = sessionStorage.getItem("jwt");

	if (!accessToken) {
		return null;
	}

	const payload = getTokenPayload(accessToken);

	if (!payload) {
		return null;
	}

	if (payload.exp < Date.now() / 1000) {
		sessionStorage.removeItem("jwt");
		return null;
	} else {
		return accessToken;
	}
};

const authLink = setContext((_, { headers, ...rest }) => {
	const accessToken = getToken();

	return {
		headers: {
			authorization: accessToken ? `Bearer ${accessToken}` : "",
			...headers,
			...(rest.preview ? { "x-preview": "true" } : {}),
		},
	};
});

const httpLink = new HttpLink({
	uri: BACKEND_URL,
	credentials: ENVIRONMENT === "development" ? undefined : "include",
	headers: {
		"Access-Control-Allow-Origin": FRONTEND_URL,
		// "Strict-Transport-Security": `max-age=${
		// 	ENVIRONMENT === "development" ? 60 * 60 * 24 : 365 * 60 * 60 * 24
		// }; includeSubDomains`,
	},
});

const linkChain = automaticallyPersistedQueryLink.concat(httpLink);

const wsLink = new GraphQLWsLink(
	createClient({
		url: BACKEND_WS_URL,
		keepAlive: 10_000,
		lazy: true,
		shouldRetry: (errOrCloseEvent) => true, // Custom retry condition
		retryAttempts: 10,
		// webSocketImpl: class extends WebSocket {
		// 	constructor(url: string, protocols?: string | string[]) {
		// 		const accessToken = getToken();
		// 		super(url, ["graphql-transport-ws", `Bearer-${accessToken}`]);
		// 	}
		// },
	}),
);

/**
 * Middlewares
 */
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);

		return definition.kind === "OperationDefinition" && definition.operation === "subscription";
	},
	wsLink,
	authLink.concat(linkChain),
);

const cleanTypenameLink = new ApolloLink((operation, forward) => {
	if (operation.variables && !operation.variables.file) {
		operation.variables = omitDeep(operation.variables, "__typename");
	}

	return forward(operation);
});

const sentryMiddleware = new ApolloLink((operation, forward) => {
	Sentry.addBreadcrumb({
		category: "query",
		message: operation.operationName,
		level: "info",
	});

	Sentry.setContext("query", {
		operationName: operation.operationName,
		variables: JSON.stringify(operation.variables),
	});

	return forward(operation);
});

/**
 * Create client
 */
const client = new ApolloClient({
	name: NAME,
	version: VERSION,
	link: ApolloLink.from([cleanTypenameLink, sentryMiddleware, splitLink]),
	cache: new InMemoryCache({
		typePolicies: {
			Chat: {
				fields: {
					messages: {
						merge(existing = [], incoming: any[]) {
							return unionBy([...existing, ...incoming], "__ref");
						},
					},
				},
			},
			Venue: {
				merge(existing, incoming) {
					return { ...existing, ...incoming };
				},
				fields: {
					settings: {
						merge(existing, incoming) {
							return { ...existing, ...incoming };
						},
					},
				},
			},
			Query: {
				fields: {
					me: {
						merge(existing, incoming) {
							return incoming;
						},
					},
				},
			},
		},
	}),
});

if (__DEV__) {
	// Adds messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

export default client;
