import { ReactComponent as MenuIcon } from "@/assets/menu.svg";
import { useTheme } from "@/system/theme";

export const Menu = ({
	color,
	fontSize = "inherit",
}: {
	color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
	fontSize?: "inherit" | "small" | "medium" | "large";
}) => {
	const theme = useTheme();

	let stroke = "inherit";

	if (color === "primary") {
		stroke = theme.palette[color].main;
	}

	return <MenuIcon stroke={stroke} fontSize={fontSize} />;
};
