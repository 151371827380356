import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => {
	return {
		background: {
			background: theme.palette.background.default,

			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "stretch",

			boxSizing: "border-box",
			height: "100vh",
		},

		header: {
			background: theme.palette.background.default,

			position: "fixed",
			padding: 16,
			top: 0,
			right: 0,
			left: 0,
			zIndex: 2,

			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",

			boxShadow: ({ scale }: { scale: number; noMargin?: boolean }) => {
				return `0px 0px ${(1 - scale) * 7}px 0px rgba(41, 19, 129, 0.1)`;
			},
		},

		main: {
			overflow: "scroll",
			display: "flex",
			flexDirection: "column",

			width: "100%",
			minHeight: "100vh",
			boxSizing: "border-box",

			"&>figure": {
				textAlign: "right" as const,
			},

			"&>article": {
				margin: "0 auto",
				minWidth: 327, // 375px - 2*24px
				maxWidth: 640,
			},

			padding: ({ noMargin }: { scale: number; noMargin?: boolean }) => {
				const my = noMargin ? 0 : 3;

				return theme.spacing(14, my, 0, my);
			},
		},

		"main--content": {
			flexGrow: 1,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},

		drawer: {
			background: theme.palette.primary.main,
			color: "white",
			display: "flex",
			flexDirection: "column",
			maxWidth: 640,
			height: "100vh",
			overflow: "auto",
		},
	};
});

export default useStyles;
