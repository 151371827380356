import { PropsWithChildren } from "react";

import MuiBox from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material";

type BoxProps = {
	m?: number;
	mx?: number;
	my?: number;
	mt?: number;
	mr?: number;
	mb?: number;
	ml?: number;
	p?: number;
	px?: number;
	py?: number;
	pt?: number;
	pr?: number;
	pb?: number;
	pl?: number;
	borderRadius?: number;
	textAlign?: "left" | "center" | "right";
	background?: string;
	className?: string;
	position?: "relative" | "absolute";
	elevation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19;
	gap?: 1 | 2 | 3 | 4;
	display?: "flex";
	justifyContent?: "center" | "flex-end";
	flexDirection?: "row" | "column";
	flex?: number;
	sx?: SxProps<Theme>;
};

export const Box = ({
	background,
	elevation,
	sx,
	...props
}: PropsWithChildren<
	BoxProps & { component: "header" | "main" | "footer" | "section" | "div" | "figure" }
>) => {
	return <MuiBox {...props} bgcolor={background} sx={{ boxShadow: elevation, ...sx }} />;
};

export const FlexBox = ({
	background,
	elevation,
	...props
}: PropsWithChildren<
	BoxProps & {
		justifyContent?: "center";
		alignItems?: "center";
		component: "header" | "main" | "footer" | "div";
	}
>) => {
	return <MuiBox {...props} display="flex" bgcolor={background} sx={{ boxShadow: elevation }} />;
};

export const Header = (props: PropsWithChildren<BoxProps>) => {
	return <Box component="header" {...props} />;
};

export const Main = (props: PropsWithChildren<BoxProps>) => {
	return <Box component="main" {...props} />;
};

export const Footer = (props: PropsWithChildren<BoxProps>) => {
	return <Box component="footer" {...props} />;
};
