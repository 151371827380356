import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { defaultNS, fallbackLng, ns, resources, supportedLngs } from "./locales/resources";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		ns,
		defaultNS,
		resources,
		fallbackLng,
		interpolation: {
			escapeValue: false,
		},
		debug: false,
		detection: {
			order: ["querystring", "localStorage", "navigator"],
			lookupQuerystring: "lang",
			lookupLocalStorage: "language",
			caches: ["localStorage"],
		},
		supportedLngs,
	});

export default i18n;
