import IconButton from "@mui/material/IconButton";

import { Icons } from "@/system/atoms/Icons";

type IconButtonProps = {
	size?: "small" | "medium" | "large";
	onBackground?: boolean;
	onClick: () => void;
};

export const Menu = (props: IconButtonProps) => {
	return (
		<IconButton color="inherit" onClick={props.onClick} aria-label="Open menu" size={props.size}>
			<Icons.Menu color={props.onBackground ? "primary" : undefined} fontSize={props.size} />
		</IconButton>
	);
};

export const Back = (props: IconButtonProps) => {
	return (
		<IconButton color="inherit" onClick={props.onClick} aria-label="Go back" size={props.size}>
			<Icons.ChevronLeft fontSize={props.size} />
		</IconButton>
	);
};

export const Info = (props: IconButtonProps) => {
	return (
		<IconButton color="inherit" onClick={props.onClick} aria-label="Info" size={props.size}>
			<Icons.Info fontSize={props.size} />
		</IconButton>
	);
};

const IconButtons = { Menu, Back, Info };

export default IconButtons;
