export const translations = {
	back: "Back",
	callDirectly: "Call directly",
	personStepHeadline: "We're here for you",
	personStepSubtitle: "Please help us with some details of the situation",
	situationStepSubtitle:
		"Thank you! What exactly happened? After the following selection, you will be connected directly to the team. This is regarding a ...",
	waitingMessage:
		"Please wait a moment, the team has been notified and will respond to you in a minute.",
	waitingHeadline: "We will respond shortly",
	waitingHeadlineAccepted: "We are on the way",
	waitingMessageAccepted: "Someone from team is on their way now and will be right with you.",
	automaticUnavailableMessage:
		"Hey, saferspaces is currently not active here. Please contact the nearest staff member.",
	automaticFirstMessage: "Hey, nice to hear from you. How can I help you?",
	immediateHelp: "I need immediate help",
	geoLocation: {
		modal: {
			denied: {
				headline: "How to enable location access",
				text: "Please allow us to use your location. This is necessary to find out if there is a support team nearby.",
				primaryButton: "Retry",
				secondaryButton: "Close",
			},
			error: {
				headline: "How to enable location access",
				text: "Please allow us to use your location. This is necessary to find out if there is a support team nearby.",
				primaryButton: "Retry",
				secondaryButton: "Close",
			},
			prompt: {
				headline: "We need your location",
				text: "Please allow us to use your location. This is necessary to find out if there is a support team nearby.",
				primaryButton: "Ok",
			},
			instructions: {
				forBrowser: "For {{browser}}:",
				ios: {
					default: [
						"If you're using {{browser}}, go to your settings and allow location access under both of these settings:",
						"{{browser}} > Location",
						"Privacy > Location Services > {{browser}}",
					],
				},
				android: {
					default: ["Click the icon to the left of the URL", "Permissions > Location"],
				},
			},
		},
		permission: {
			denied: {
				headline: "We'll be right there for you",
				text: "",
			},
			prompt: {
				headline: "We'll be right there for you",
				text: "",
			},
			requesting: {
				headline: "We'll be right there for you",
				text: "Your location is being requested. Please wait a moment.",
			},
			granted: {
				headline: "We'll be right there for you",
				text: "Please wait a moment, we are looking for a team nearby.",
			},
			error: {
				headline: "The location determination failed",
				text: "Your current location could not be determined. This may be because your browser does not support a location request or you have rejected the request. Please try again or contact the staff on site directly.",
			},
		},
		errors: {
			"1": "Your location could not be determined",
			"2": "Your current location could not be determined. This may be because your browser does not support a location request or you have rejected the request. Please try again or contact the staff on site directly.",
			"3": "Your location could not be determined. Please try again or contact the staff on site directly.",
			"4": "Your current location could not be determined. This may be because your browser does not support a location request.",
			"5": "Your location could not be determined. Please try again or contact the staff on site directly.",
		},
	},
	general: {
		ok: "Ok",
		retry: "Try again",
		sending: "Sending",
	},
	role: {
		headline: "We're here for you",
		subtitle: "Please help us with some details of the situation",
		victim: "I am affected",
		witness: "I am a witness",
	},
	situation: {
		subtitle: "Ok! What kind of situation is it about?",
		attacked: "Physical attack",
		harassed: "Sexual Harassment",
		discriminated: "Discrimination",
		emergency: "Medical emergency",
		somethingWrong: "Something else",
		notify: "Something else",
		unknown: "Something else",
	},
	qrCodeInternalChat: {
		request: "The team has been notified",
		response: "Somebody is on the way",
	},
	confirm: {
		headline: "We're here for you",
		subtitle: "By clicking the button, the team will be notified with your current location",
		feedback: "Give feedback",
		button: "Immediate help",
	},
	drawer: {
		homepage: "Homepage",
		aboutUs: "About us",
		headline: "Let’s take <1>care</1>!",
		textLine1:
			"Saferspaces connects people who experience or observe unsafe situations with the people on site who are there to support those affected.",
		textLine2:
			"There is no need to download an app to get in touch with the trained staff,  contact can easily be made by scanning the saferspaces QR codes. By using saferspaces, the often challenging step of actively approaching an unknown person and asking for help is made easier in a low-threshold and targeted manner.",
		textLine3: "For a <1>respectful</1> togetherness!",
		textLine4: "Saferspaces",
		sendHeadline: "Thanks for your <1>feedback</1> concerning our application",
		subject: "Subject",
		message: "Message",
		send: "Send",
	},
	lastVisitedChat: {
		text: "You haven been in contact with the team recently. Do you want to continue the conversation?",
		yes: "Yes",
		no: "No",
	},
	venue: {
		inactiveTitle: "Hi!",
		inactiveText:
			"Currently, saferspaces is not active here. If you need assistance, please contact the security or team directly.",
		chat: {
			send: "Send message",
			subTitle: "The team has received your message.",
		},
	},
	home: {
		title: "There is no one on duty here",
		description:
			"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
	},
	qrCode: {
		qrCodeAnonymous: {
			errorHeadline: "An <1>error</1> occured",
			errorMessage:
				"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
			errorUserFeedbackMessage:
				"We have been informed, that an error occurred. You can help us to find out more about the error by providing some details of what you did immediately before:",
			qrCodeDynamic: {
				errorSubmit:
					"An <1>error</1> occured. Please try again or contact us at support@saferspaces.io",
				successSubmit: "Thanks!",
				successTitle: "<0>Thank</0> you for your trust!",
				successMessage: "The message has been forwarded to the appropriate person.",
			},
		},
		qrCodeHelp: {
			handshake: {
				alt: "Handshake symbol",
			},
		},
		qrCodeInternalChat: {
			headline: "Do you need <1>support?</1>",
			hint: "Press the button to notify the team. Location: ",
			sendSevere: "Immediate help",
			send: "Report something",
			confirmationText: "You are currently here",
			confirm: "Confirm",
		},
	},
	inactiveQRCode: {
		headline: "This code is currently not active",
		subtitle:
			"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
	},
	inactive: {
		headline: "Currently there is no one on duty here",
		subtitle:
			"We hope that you receive help as quickly as possible. Please reach out to people in your surrounding. In case of an emergency, dial 112, the European emergency number free of charge to receive assistance.",
		feedback: "Report something",
	},
	venueGeofence: {
		headline: "Let’s take care of each other",
		subtitle: "We want everyone to feel comfortable and safe with us.",
		feedback: "Report something",
	},
} as const;
